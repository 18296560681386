import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import AwsImage from "../images/logo_aws.png";
import GitHubImage from "../images/logo_github.png";
import AtlassianImage from "../images/logo_atlassian.png";
import ZoomImage from "../images/logo_zoom.png";
import CoinbaseImage from "../images/logo_coinbase.png";
import SentryImage from "../images/logo_sentry.png";

import Container from "../components/container";

const LOGO_SX = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  height: 24,
  opacity: 0.4,
  mb: 12,
  "& img": { maxHeight: "100%" },
};

const serviceLogos = [
  { alt: "aws logo", src: AwsImage },
  { alt: "github logo", src: GitHubImage },
  { alt: "atlassian logo", src: AtlassianImage },
  { alt: "zoom logo", src: ZoomImage },
  { alt: "coinbase logo", src: CoinbaseImage },
  { alt: "sentry logo", src: SentryImage },
];

const leftLogos = serviceLogos.slice(0, 3);
const rightLogos = serviceLogos.slice(3, 6);

function LogoItem(logo) {
  return (
    <Grid
      item
      xs={4}
      md={2}
      sx={{
        ...LOGO_SX,
        height: 40,
        mb: 0,
      }}
    >
      <img alt={logo.alt} {...logo} />
    </Grid>
  );
}

export default function ServiceLogos({
  heading = "Keep track of your favorite services",
  sx = {},
}) {
  return (
    <Container sx={sx}>
      <Typography fontSize="large" sx={{ textAlign: "center", mb: 2 }}>
        {heading}
      </Typography>

      {/* MOBILE LOGOS */}
      <Grid container spacing={2} sx={{ display: { md: "none" } }}>
        {leftLogos.map((logo) => (
          <LogoItem key={logo.alt} {...logo} />
        ))}
        {rightLogos.map((logo) => (
          <LogoItem key={logo.alt} {...logo} />
        ))}
      </Grid>

      {/* DESKTOP LOGOS */}
      <Box sx={{ ...LOGO_SX, display: { xs: "none", md: "flex" } }}>
        {leftLogos.map((logo, idx) => (
          <img key={idx} alt={logo.alt} {...logo} />
        ))}
        {rightLogos.map((logo, idx) => (
          <img key={idx} alt={logo.alt} {...logo} />
        ))}
      </Box>
    </Container>
  );
}
