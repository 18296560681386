import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

import DashboardImage from "../images/dashboard_lg.jpg";
import NotificationsImage from "../images/notifications_lg.jpg";
import ServicesImage from "../images/services_lg.jpg";

import Container from "./container";

function Feature({ heading, src, reverse = false, subheading }) {
  return (
    <Grid
      container
      spacing={8}
      sx={{
        mb: { xs: 4, md: 8 },
        flexDirection: reverse ? "row-reverse" : "row",
        pb: 4,
        pt: { md: 10, lg: 15 },
      }}
    >
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <FeatureImage src={src} alt={heading} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="h3"
          fontWeight="fontWeightBold"
          sx={{ mb: 2, textAlign: { xs: "center", md: "left" } }}
        >
          {heading}
        </Typography>
        <FeatureImage
          src={src}
          alt={heading}
          sx={{
            borderRadius: 1,
            display: { md: "none" },
            my: { xs: 12, sm: 15 },
          }}
        />
        <Typography fontSize="large" sx={{ maxWidth: {} }}>
          {subheading}
        </Typography>
      </Grid>
    </Grid>
  );
}

function FeatureImage({ alt, src, sx = {} }) {
  return (
    <Box sx={{ position: "relative", ...sx }}>
      <Box
        sx={{
          left: "2rem",
          right: "2rem",
          aspectRatio: "1 / 1",
          position: "absolute",
          borderWidth: { xs: 30, sm: 40, lg: 50 },
          borderColor: "secondary.main",
          borderRadius: "50%",
          borderStyle: "solid",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />
      <Box
        component={Paper}
        elevation={24}
        sx={{ position: "relative", overflow: "hidden", borderRadius: 1 }}
      >
        <img src={src} alt={alt} style={{ width: "100%" }} />
      </Box>
    </Box>
  );
}

export default function About() {
  return (
    <Box
      component="section"
      sx={{
        pb: 10,
      }}
    >
      <Container sx={{ maxWidth: { md: "lg" } }}>
        <Feature
          reverse
          src={DashboardImage}
          heading="Status Dashboard"
          subheading={
            <>
              Our user-friendly dashboard helps streamline your workflow, track
              all of your external cloud services and revolutionize the way you
              work. It's never been faster or more efficient to monitor,
              troubleshoot and view the real-time status of all your third-party
              services than with StatusVista.
            </>
          }
        />
        <Feature
          src={NotificationsImage}
          heading="Incident Notifications"
          subheading={
            <>
              With Email and Slack integrations available, you and your team
              will know immediately when a system goes down. Rest easier knowing
              StatusVista is always awake and informing you of incident events.
              Never miss another outage.
            </>
          }
        />
        <Feature
          reverse
          src={ServicesImage}
          heading="Customized Services"
          subheading={
            <>
              While there are over 3,000 services available, you configure which
              ones you care about and only see status information relevant to
              your business. As your needs change, add or remove services to the
              dashboard with an easy-to-use filtering system.
            </>
          }
        />
      </Container>
    </Box>
  );
}
