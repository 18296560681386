import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { ScrollProvider, useScrollContext } from "../contexts/scroll";

import About from "../components/about";
import Anchor from "../components/anchor";
import Contact from "../components/contact";
import Container from "../components/container";
import Cta from "../components/cta";
import Features from "../components/features";
import Footer from "../components/footer";
import Header from "../components/header";
import PageWrapper from "../components/page-wrapper";
import ServiceLogos from "../components/service-logos";
import { SignupButtons } from "../components/signup-button";
import Stats from "../components/stats";

const pageDescription =
  "The ultimate status dashboard for developers. With the status of everything you depend on in one place, you can monitor services faster and more efficiently than ever before.";
const pageTitle = "Features";

function Hero() {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: "primary.main",
        color: "common.white",
        pt: { xs: 2, md: 15 },
        pb: 10,
      }}
    >
      <Container>
        <Typography
          component="h1"
          variant="h2"
          fontWeight="fontWeightBold"
          sx={{ mb: 2, maxWidth: "md" }}
        >
          Simplify your cloud service monitoring
        </Typography>
        <Typography fontSize="larger">
          Everything you need to track outages. Get started today for{" "}
          <strong>free</strong>.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            <SignupButtons
              emailProps={{ color: "tertiary" }}
              googleProps={{ color: "tertiary", variant: "outlined" }}
            />
          </Stack>
          <Stack spacing={1} sx={{ display: { xs: "flex", sm: "none" } }}>
            <SignupButtons
              emailProps={{ color: "tertiary" }}
              googleProps={{ color: "tertiary", variant: "outlined" }}
            />
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

function FeaturesPage() {
  const { headerHeight, pageContentRef } = useScrollContext();

  return (
    <>
      <Header variant="primary" />
      <Hero />
      <Anchor ref={pageContentRef} offset={headerHeight} />
      <Box>
        <ServiceLogos
          heading="Supporting your favorite services"
          sx={{ mt: 4, mb: { xs: 8, md: 0 } }}
        />
        <About />
        <Anchor id="features" offset={headerHeight} />
        <Features heading="Feature highlights" />
        <Stats variant="light" sx={{ mb: 10 }} />
        <Anchor id="contact" offset={headerHeight} />
        <Contact />
        <Cta />
      </Box>
      <Footer />
    </>
  );
}

export default function Page() {
  return (
    <PageWrapper pageDescription={pageDescription} pageTitle={pageTitle}>
      <ScrollProvider>
        <FeaturesPage />
      </ScrollProvider>
    </PageWrapper>
  );
}
